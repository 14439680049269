export const PropertyListItemHover = () => {
    return <div className="group-26">
        <div className="overlap-19">
            <div className="group-27">
                <div className="group-28">
                    <div className="overlap-group-7">
                        <img className="vector-9" alt="Vector" src="/img/vector-3-4.svg" />
                        <img className="vector-10" alt="Vector" src="/img/vector-2-4.svg" />
                        <img className="vector-13" alt="Vector" src="/img/vector-4.svg" />
                        <div className="text-wrapper-52">2Bedrooms</div>
                        <div className="text-wrapper-53">3 Bath</div>
                        <img className="vector-14" alt="Vector" src="/img/vector.svg" />
                        <div className="element-sq-ft-2"> 1000 sq ft</div>
                    </div>
                </div>
            </div>
            <div className="CTA-4">
                <div className="text-wrapper-51">View Details</div>
            </div>
            <img className="rectangle-13" alt="Rectangle" src="/img/rectangle-221-1.png" />
            <div className="text-wrapper-54">La casa en</div>
            <div className="text-wrapper-55">South of France</div>
            <div className="text-wrapper-56">3.333.000 €</div>
        </div>
    </div>
}