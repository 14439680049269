import React from 'react';
import { PropertyText } from '../Properties/PropertyText';
import { PropertyListItem } from './PropertyListItem';
import { PropertyListItemHover } from './PropertyListItemHover';
export const PropertyList = () => {
    return (
        <div className="featured-properties">

            <PropertyText></PropertyText>
            <PropertyListItem></PropertyListItem>
            <PropertyListItemHover></PropertyListItemHover>
            <div className="group-29">
                <div className="overlap-18">
                    <div className="group-27">
                        <div className="group-28">
                            <div className="overlap-group-8">
                                <img className="vector-9" alt="Vector" src="/img/vector-3-3.svg" />
                                <img className="vector-10" alt="Vector" src="/img/vector-2-3.svg" />
                                <img className="vector-13" alt="Vector" src="/img/vector-4.svg" />
                                <div className="text-wrapper-57">2Bedrooms</div>
                                <div className="text-wrapper-58">3 Bath</div>
                                <img className="vector-14" alt="Vector" src="/img/vector.svg" />
                                <div className="element-sq-ft-3"> 1000 sq ft</div>
                            </div>
                        </div>
                    </div>
                    <div className="CTA-3">
                        <div className="text-wrapper-51">View Details</div>
                    </div>
                    <img className="rectangle-13" alt="Rectangle" src="/img/rectangle-229-1.png" />
                    <div className="text-wrapper-59">Chrysler Front</div>
                    <div className="text-wrapper-60">Manchester, UK</div>
                    <div className="text-wrapper-49">390.000 €</div>
                    <div className="frame-18">
                        <div className="text-wrapper-50">AR4171582</div>
                    </div>
                    <img className="heart" alt="Heart" src="/img/heart-6-1-1.png" />
                </div>
            </div>
            <div className="group-30">
                <div className="overlap-18">
                    <div className="group-27">
                        <div className="overlap-group-9">
                            <div className="group-31">
                                <img className="vector-9" alt="Vector" src="/img/vector-3-2.svg" />
                                <img className="vector-10" alt="Vector" src="/img/vector-2.svg" />
                            </div>
                            <div className="frame-19">
                                <img className="fluent-bed" alt="Fluent bed" src="/img/fluent-bed-20-regular-2.svg" />
                                <div className="text-wrapper-61">2Bedrooms</div>
                            </div>
                            <div className="frame-20">
                                <div className="text-wrapper-62">3 Bath</div>
                            </div>
                            <div className="element-sq-ft-wrapper">
                                <div className="element-sq-ft-4"> 1000 sq ft</div>
                            </div>
                        </div>
                    </div>
                    <img className="rectangle-13" alt="Rectangle" src="/img/rectangle-227.png" />
                    <div className="text-wrapper-47">Marten House</div>
                    <div className="text-wrapper-49">390.000 €</div>
                    <div className="text-wrapper-60">Marbella, Spain</div>
                    <div className="frame-18">
                        <div className="text-wrapper-50">AR4171582</div>
                    </div>
                    <img className="heart" alt="Heart" src="/img/heart-6-1-1.png" />
                    <div className="CTA-3">
                        <div className="text-wrapper-51">View Details</div>
                    </div>
                </div>
            </div>
            <div className="group-32">
                <div className="overlap-18">
                    <div className="group-27">
                        <div className="overlap-group-9">
                            <div className="group-25">
                                <img className="vector-9" alt="Vector" src="/img/vector-3.svg" />
                                <img className="vector-10" alt="Vector" src="/img/vector-2.svg" />
                            </div>
                            <div className="frame-21">
                                <img className="fluent-bed" alt="Fluent bed" src="/img/fluent-bed-20-regular-1.svg" />
                                <div className="text-wrapper-61">2Bedrooms</div>
                            </div>
                            <div className="frame-22">
                                <div className="text-wrapper-62">3 Bath</div>
                            </div>
                            <div className="frame-23">
                                <div className="element-sq-ft-4"> 1000 sq ft</div>
                            </div>
                        </div>
                    </div>
                    <div className="CTA-3">
                        <div className="text-wrapper-51">View Details</div>
                    </div>
                    <img className="rectangle-13" alt="Rectangle" src="/img/rectangle-221.png" />
                    <div className="text-wrapper-54">La casa en</div>
                    <div className="text-wrapper-56">3.333.000 €</div>
                    <div className="text-wrapper-60">Marbella, Spain</div>
                    <div className="frame-18">
                        <div className="text-wrapper-50">AR4171582</div>
                    </div>
                    <img className="heart" alt="Heart" src="/img/heart-6-1-1.png" />
                </div>
            </div>
            <div className="group-33">
                <div className="overlap-18">
                    <div className="group-27">
                        <div className="overlap-group-9">
                            <div className="group-25">
                                <img className="vector-9" alt="Vector" src="/img/vector-3.svg" />
                                <img className="vector-10" alt="Vector" src="/img/vector-2.svg" />
                            </div>
                            <div className="frame-21">
                                <img className="fluent-bed" alt="Fluent bed" src="/img/fluent-bed-20-regular.svg" />
                                <div className="text-wrapper-61">2Bedrooms</div>
                            </div>
                            <div className="frame-22">
                                <div className="text-wrapper-62">3 Bath</div>
                            </div>
                            <div className="frame-23">
                                <div className="element-sq-ft-4"> 1000 sq ft</div>
                            </div>
                        </div>
                    </div>
                    <div className="CTA-3">
                        <div className="text-wrapper-51">View Details</div>
                    </div>
                    <img className="rectangle-13" alt="Rectangle" src="/img/rectangle-229.png" />
                    <div className="text-wrapper-59">Chrysler Front</div>
                    <div className="text-wrapper-60">Marbella, Spain</div>
                    <div className="text-wrapper-49">390.000 €</div>
                    <div className="frame-18">
                        <div className="text-wrapper-50">AR4171582</div>
                    </div>
                    <img className="heart" alt="Heart" src="/img/heart-6-1-1.png" />
                </div>
            </div>
        </div>
    )
};