export const SearchBar = () => {
    return (
        <div className="searchbar">
            <div className="overlap-22">
                <div className="rectangle-14" />
                <img className="vector-18" alt="Vector" src="/img/vector-1.svg" />
                <div className="search-tab">
                    <div className="rent">RENT</div>
                    <div className="buy">BUY</div>
                    <div className="sell">SELL</div>
                </div>
                <div className="rectangle-15" />
                <div className="search-button">
                    <img className="group-41" alt="Group" src="/img/group-1.png" />
                </div>
                <div className="search-types">
                    <div className="overlap-group-12">
                        <div className="text-wrapper-72">Location</div>
                        <div className="text-wrapper-73">Select Your City</div>
                    </div>
                    <div className="overlap-23">
                        <div className="text-wrapper-72">Property Type</div>
                        <div className="text-wrapper-73">Choose Property Type</div>
                    </div>
                    <div className="overlap-24">
                        <div className="text-wrapper-72">Price Range</div>
                        <div className="text-wrapper-73">Choose Price Range</div>
                    </div>
                    <img className="line" alt="Line" src="/img/line-3.svg" />
                    <img className="line-2" alt="Line" src="/img/line-3.svg" />
                    <img className="group-42" alt="Group" src="/img/group-3.png" />
                    <img className="group-43" alt="Group" src="/img/group-4.png" />
                </div>
            </div>
        </div>
    );
}