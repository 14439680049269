export const Menu = () => {
    return (
        <div className="meniu">
            <div className="group-34">
                <div className="group-35">
                    <div className="overlap-group-10">
                        <div className="ellipse-4" />
                        <div className="text-wrapper-63">Home</div>
                    </div>
                    <div className="text-wrapper-64">Properties</div>
                    <div className="text-wrapper-65">Honest View</div>
                    <div className="text-wrapper-66">Golden Visa</div>
                </div>
                <div className="group-36">
                    <div className="text-wrapper-67">Blog</div>
                    <div className="text-wrapper-68">About us</div>
                    <div className="text-wrapper-69">Contact</div>
                    <div className="group-37">
                        <div className="text-wrapper-70">My Account</div>
                    </div>
                </div>
            </div>
        </div>
    );
}