import React from 'react';
import { IconlyBoldArrowWrapper } from "../../components/IconlyBoldArrowWrapper";

export const PropertyText = () => {
    return (
        <React.Fragment>
            <div className="frame-16">
                <div className="text-wrapper-42">View More</div>
                <IconlyBoldArrowWrapper
                    className="iconly-bold-arrow-instance"
                    iconlyBoldArrowArrowRightCircleClassName="design-component-instance-node"
                />
            </div>
            <div className="group-22">
                <div className="text-wrapper-43">Featured Properties</div>
            </div>
            <p className="text-wrapper-44">
                Discover our exclusive selection of the finest one-of-a-kind luxury properties architectural masterpieces.
            </p>
        </React.Fragment>
    )
}