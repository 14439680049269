export const AiPowerSearch = () => {
    return (
        <div className="AI-search">
            <img className="mask-group" alt="Mask group" src="/img/mask-group-1.png" />
            <div className="group-9">
                <div className="AI-powered-search">
                    AI-powered
                    <br />
                    Search and Help
                </div>
                <div className="flex-container-3">
                    <div className="text-3">
                        <span className="text-wrapper-25">
                            Wish to play a bit with your options and get personalised recommended properties for your unique
                            tastes?
                            <br />
                        </span>
                    </div>
                    <div className="text-3">
                        <span className="text-wrapper-25">
                            We introduce you our powerful AI search tool - just ask for your custom request and get all relevant
                            options for you in real time!
                            <br />
                        </span>
                    </div>
                    <div className="text-3">
                        <span className="text-wrapper-25">
                            “2 bedroom apartment in mijas” or “luxury villa for family of 5” - go as specific as you wish (we’ll
                            make sure your request reaches us and you get it answered).
                        </span>
                    </div>
                </div>
            </div>
            <div className="use-AI-search-wrapper">
                <div className="use-AI-search">Use Ai Search</div>
            </div>
        </div>
    );
}