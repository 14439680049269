export const LetUsHelpYou = () => {
    return (
        <div className="let-us-help-you">
            <div className="group-wrapper">
                <div className="div-wrapper">
                    <div className="overlap-group-4">
                        <div className="guide-me-wrapper">
                            <div className="guide-me">Guide Me</div>
                        </div>
                        <div className="tailor-made-wrapper">
                            <p className="tailor-made">
                                <span className="span">Tailor made assistance for </span>
                                <span className="text-wrapper-26">your unique needs</span>
                            </p>
                        </div>
                        <p className="text-wrapper-27">Are you in a hurry? Or is your request super specific?</p>
                        <img className="vector-5" alt="Vector" src="/img/vector-56.svg" />
                        <p className="text-wrapper-28">
                            If you prefer to obtain a personalized list of custom options you can choose from before making your
                            move just fill in the custom support form based on your wishes and let us select the most relevant and
                            attractive locations for you!
                        </p>
                    </div>
                </div>
            </div>
            <div className="let-us-help-you-wrapper">
                <div className="let-us-help-you-2">Let Us Help You</div>
            </div>
        </div>
    )
}