export const MovingInMainText = () => {
    return (
        <div className="moving-with-ur">
            <div className="group-16">
                <div className="overlap-14">
                    <div className="p-wrapper">
                        <p className="text-wrapper-35">
                            Let us guide you. We are highly experienced with family relocation, considering that we ourselves
                            have moved in here for more than 10 years, with our happy pack of four children. So we are super
                            acquainted with all the do’s and dont’s, tips and tricks, pros and cons of choosing one school over
                            another, one particular area over another and so on. We’ve experienced them all and know how to best
                            advise you.
                        </p>
                    </div>
                    <div className="group-17">
                        <div className="overlap-15">
                            <div className="overlap-16">
                                <img className="group-18" alt="Group" src="/img/group-12483.png" />
                                <div className="overlap-group-5">
                                    <div className="rectangle-12" />
                                    <img className="group-19" alt="Group" src="/img/group-12484.png" />
                                    <img className="vector-6" alt="Vector" src="/img/vector-55.svg" />
                                    <img className="vector-7" alt="Vector" src="/img/vector-55.svg" />
                                    <img className="vector-8" alt="Vector" src="/img/vector-55.svg" />
                                    <p className="text-wrapper-36">Obtaining the resident permits for the family</p>
                                    <p className="text-wrapper-37">Choosing the best school for your children</p>
                                    <p className="text-wrapper-38">
                                        Choosing the right area to live and finding the best home for your family
                                    </p>
                                </div>
                                <img className="layer" alt="Layer" src="/img/layer-1.svg" />
                                <img className="capa" alt="Capa" src="/img/capa-1.svg" />
                            </div>
                            <p className="text-wrapper-39">Your every day (new) life in Marbella.</p>
                        </div>
                    </div>
                    <div className="CTA-wrapper">
                        <div className="CTA-2">
                            <div className="text-wrapper-40">Find Out More</div>
                        </div>
                    </div>
                    <div className="group-20" />
                </div>
            </div>
            <div className="group-21">
                <div className="moving-in-with-your-wrapper">
                    <p className="moving-in-with-your">Moving In With Your Family?</p>
                </div>
            </div>
        </div>
    )
}