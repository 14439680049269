import React from 'react';
export const MainSlider = () => {
    return (
        <React.Fragment>
            <div className="marbella-property">
                <span className="text-wrapper-74">Marbella</span>
                <span className="text-wrapper-75"> Property Advisors</span>
            </div>
            <p className="text-wrapper-76">Enjoy life in the way we all secretly dream about</p>
        </React.Fragment>
    )
};
