/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { IconlyBoldArrow } from "../IconlyBoldArrow";
import "./style.css";

export const IconlyBoldArrowWrapper = ({ className, iconlyBoldArrowArrowRightCircleClassName }) => {
  return (
    <div className={`iconly-bold-arrow-wrapper ${className}`}>
      <IconlyBoldArrow
        arrowRightCircleClassName={iconlyBoldArrowArrowRightCircleClassName}
        className="iconly-bold-arrow-right-circle"
      />
    </div>
  );
};
