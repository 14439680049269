import { IconlyBoldArrowWrapper } from "../IconlyBoldArrowWrapper";
import { OtherProjects } from '../otherProjects/otherprojects';
export const NewDevelopments = () => {
    return (
        <div className="new-delopments">
            <div className="overlap-6">
                <div className="rectangle-5" />
                <div className="text-wrapper-21">320Estates New Developments</div>
                <div className="property-default-wrapper">
                    <OtherProjects className="property-default" property1="default" />
                </div>
                <div className="group-7">
                    <div className="frame-wrapper">
                        <div className="frame-10">
                            <div className="text-wrapper-22">890,000 EUR</div>
                            <div className="text-wrapper-23">BAHÍA DE MARBELLA</div>
                        </div>
                    </div>
                    <div className="overlap-7">
                        <div className="rectangle-6" />
                        <img className="group-8" alt="Group" src="/img/group-12455.png" />
                    </div>
                    <div className="frame-11">
                        <div className="text-wrapper-22">330,000 EUR</div>
                        <div className="text-wrapper-23">NAGÜELES</div>
                    </div>
                </div>
                <div className="frame-12">
                    <div className="text-wrapper-24">All Properties</div>
                    <IconlyBoldArrowWrapper
                        className="iconly-bold-arrow-instance"
                        iconlyBoldArrowArrowRightCircleClassName="iconly-bold-arrow-right-circle-instance"
                    />
                </div>
                <p className="p">
                    The South Coast of Spain and particularly Marbella offers a unique combination of attributes, unsurpassed
                    by any other place on the whole Mediterranean seashores.
                </p>
            </div>
        </div>
    )
}
