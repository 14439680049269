export const SubSlider = () => {
    return (
        <div className="overlap">
            <p className="text-wrapper">We live here, every day.</p>
            <div className="group-2">
                <div className="overlap-group-wrapper">
                    <div className="overlap-group-2">
                        <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-5-4.svg" />
                        <div className="about-us">About Us</div>
                    </div>
                </div>
            </div>
            <p className="whether-you-plan-to">
                WHETHER YOU PLAN TO MOVE OVER IN THE SUNSHINE WITH YOUR 3 KIDS FAMILY, YOU’RE PLANNING YOUR RETIREMENT IN A
                CORNER OF PARADISE WHERE THEY PLAY A LOT OF GOLF, OR MAYBE JUST LOOKING TO MAKE A PROFITABLE REAL-ESTATE
                INVESTMENT, WE HAVE ALL THE ANSWERS FOR YOU
            </p>
        </div>
    );
}