export const LifeStyle = () => {
    return (
        <div className="blog-lifestyle">
            <div className="group-10">
                <div className="andalusian-lifestyle-wrapper">
                    <div className="andalusian-lifestyle">Andalusian Lifestyle</div>
                </div>
            </div>
            <div className="overlap-9">
                <div className="card-wrapper">
                    <div className="card">
                        <img className="placeholder-image" alt="Placeholder image" src="/img/placeholder-image-3.png" />
                        <div className="content">
                            <div className="content-2">
                                <div className="info">
                                    <div className="frame-13">
                                        <div className="text-wrapper-29">Categorie</div>
                                    </div>
                                    <div className="text-wrapper-30">5 min read</div>
                                </div>
                                <div className="title">
                                    <p className="text-wrapper-31">Lorem Ipsum dolor sit amet</p>
                                    <p className="text-wrapper-32">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                        laboris
                                    </p>
                                </div>
                            </div>
                            <div className="button">
                                <div className="group-11">
                                    <div className="overlap-group-2">
                                        <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-5-3.svg" />
                                        <div className="read-more">Read More</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rectangle-7" />
            </div>
            <div className="overlap-10">
                <div className="group-12">
                    <div className="overlap-11">
                        <img className="placeholder-image-2" alt="Placeholder image" src="/img/placeholder-image-2.png" />
                        <div className="rectangle-8" />
                        <div className="content-3">
                            <div className="info">
                                <div className="frame-14">
                                    <div className="text-wrapper-29">Categorie</div>
                                </div>
                                <div className="text-wrapper-30">5 min read</div>
                            </div>
                            <div className="title-wrapper">
                                <div className="title-2">
                                    <p className="text-wrapper-33">Duis aute irure dolor in reprehenderit</p>
                                    <p className="text-wrapper-34">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="button-2">
                            <div className="group-13">
                                <div className="overlap-group-2">
                                    <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-5-2.svg" />
                                    <div className="read-more">Read More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rectangle-9" />
            </div>
            <div className="group-14">
                <div className="overlap-12">
                    <img className="placeholder-image-3" alt="Placeholder image" src="/img/placeholder-image-1.png" />
                    <div className="rectangle-10" />
                    <div className="content-4">
                        <div className="info">
                            <div className="frame-14">
                                <div className="text-wrapper-29">Categorie</div>
                            </div>
                            <div className="text-wrapper-30">5 min read</div>
                        </div>
                        <div className="title-wrapper">
                            <div className="title-2">
                                <p className="text-wrapper-33">Duis aute irure dolor in reprehenderit</p>
                                <p className="text-wrapper-34">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="button-3">
                        <div className="group-15">
                            <div className="overlap-group-2">
                                <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-5-1.svg" />
                                <div className="read-more">Read More</div>
                            </div>
                        </div>
                    </div>
                    <div className="rectangle-11" />
                </div>
            </div>
            <div className="overlap-13">
                <div className="group-12">
                    <div className="overlap-11">
                        <img className="placeholder-image-2" alt="Placeholder image" src="/img/placeholder-image.png" />
                        <div className="rectangle-8" />
                        <div className="content-3">
                            <div className="info">
                                <div className="frame-14">
                                    <div className="text-wrapper-29">Categorie</div>
                                </div>
                                <div className="text-wrapper-30">5 min read</div>
                            </div>
                            <div className="title-wrapper">
                                <div className="title-2">
                                    <p className="text-wrapper-33">Duis aute irure dolor in reprehenderit</p>
                                    <p className="text-wrapper-34">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="button-2">
                            <div className="group-13">
                                <div className="overlap-group-2">
                                    <img className="rectangle-4" alt="Rectangle" src="/img/rectangle-5.svg" />
                                    <div className="read-more">Read More</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rectangle-9" />
            </div>
        </div>
    )
}