import React from "react";


import { AiPowerSearch } from "../../components/AI/aiPowerSearch";
import { LetUsHelpYou } from "../../components/LetUseHelpYou/letUsHelpYou";
import { LifeStyle } from "../../components/LifeStyle/LifeStyle";
import { MovingInMainText } from "../../components/MovingIn/movingInMainText";
import { PropertyList } from "../../components/Properties/PropertyList";
import { SubSlider } from "../../components/SubSlider/SubSlider";
import { Logo } from "../../components/logo/logo";
import { MainSlider } from "../../components/mainSlider/mainSlider";
import { Menu } from "../../components/menu/Menu";
import { NewDevelopments } from "../../components/newDevelopments/newdevelopments";
import { SearchBar } from "../../components/seachBar/searchBar";
import "./style.css";

export const Desktop = () => {
  return (
    <div className="desktop">
      <div className="div-2">
        <SubSlider></SubSlider>
        <div className="contact-us">
          <div className="overlap-2">
            <div className="CTA">
              <div className="text-wrapper-2">Submit</div>
            </div>
            <h1 className="did-you-find-your">
              <span className="span">Did You Find Your</span>
              <span className="text-wrapper-3">&nbsp;</span>
              <span className="text-wrapper-4">Dream Home?</span>
            </h1>
            <div className="flex-container">
              <div className="text">
                <span className="text-wrapper-5">
                  Contact an expert today!
                  <br />
                </span>
              </div>
              <div className="text">
                <span className="text-wrapper-5">We always aim to reply within 24 hours.</span>
              </div>
            </div>
            <div className="overlap-wrapper">
              <div className="overlap-3">
                <div className="overlap-group-3">
                  <div className="frame-2">
                    <div className="frame-3">
                      <div className="text-wrapper-6">Your Name</div>
                      <input type="text" className="inputTextFooter" />
                    </div>
                    <div className="frame-3">
                      <div className="text-wrapper-6">Phone Number</div>
                      <div class="inputContainer">
                        <input type="text" className="inputTextFooter" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-4">
                    <div className="text-wrapper-7">Message</div>
                    <input type="text" className="inputTextFooter" />
                  </div>
                </div>
                <div className="frame-5">
                  <div className="text-wrapper-6">Your Email</div>
                  <input type="text" className="inputTextFooter" />
                </div>
                <div className="frame-6">
                  <div className="frame-7">
                    <div className="text-wrapper-8">Interested in</div>
                    <input type="text" className="inputTextFooter" />
                  </div>
                  <img className="vector-3" alt="Vector" src="/img/vector-51.svg" />
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-4">
            <img className="subtract" alt="Subtract" src="/img/subtract.svg" />
            <div className="group-3">
              <img className="logo" alt="Logo" src="/img/logo-320-2.png" />
              <div className="text-wrapper-9">About us</div>
              <div className="text-wrapper-10">Contact us</div>
              <div className="text-wrapper-11">Properties</div>
              <div className="group-4">
                <div className="text-wrapper-12">Marbella</div>
                <div className="text-wrapper-13">Altos de los Monteros</div>
                <div className="text-wrapper-14">La Cala de Mijas</div>
                <div className="text-wrapper-15">Nueva Andalucia</div>
                <div className="text-wrapper-16">The Golden Mile</div>
                <div className="text-wrapper-17">La Quinta</div>
              </div>
              <div className="overlap-5">
                <div className="group-5">
                  <div className="text-wrapper-12">Our services</div>
                  <div className="text-wrapper-13">Our Team</div>
                </div>
                <div className="flex-container-2">
                  <div className="span-wrapper">
                    <span className="text-wrapper-18">
                      Urb. Monte Paraiso Country Club, 4/2, 29602 Marbella
                      <br />
                    </span>
                  </div>
                  <div className="text-2">
                    <span className="text-wrapper-18">
                      Teléfono:
                      <br />
                    </span>
                  </div>
                  <div className="text-2">
                    <span className="text-wrapper-18">
                      +34658540533
                      <br />
                    </span>
                  </div>
                  <div className="text-2">
                    <span className="text-wrapper-18">
                      +34658540533
                      <br />
                    </span>
                  </div>
                  <div className="text-2">
                    <span className="text-wrapper-18">
                      <br />
                    </span>
                  </div>
                  <div className="text-2">
                    <span className="text-wrapper-18">hola@320estates.com</span>
                  </div>
                </div>
              </div>
              <div className="frame-8">
                <div className="text-wrapper-19">Follow Us</div>
                <div className="frame-9">
                  <div className="img-wrapper">
                    <img className="img-2" alt="Akar icons instagram" src="/img/akar-icons-instagram-fill.svg" />
                  </div>
                  <div className="img-wrapper">
                    <img className="img-2" alt="Ant design youtube" src="/img/ant-design-youtube-outlined.svg" />
                  </div>
                  <div className="img-wrapper">
                    <img className="img-2" alt="Simple line icons" src="/img/simple-line-icons-social-twitter.svg" />
                  </div>
                </div>
              </div>
              <div className="group-6">
                <div className="android-globe" />
                <div className="text-wrapper-20">English - En</div>
                <img className="vector-4" alt="Vector" src="/img/vector-9.svg" />
              </div>
              <div className="divider" />
              <div className="element-alyeska-resor">Copyright © 2023 320Estates</div>
            </div>
          </div>
        </div>
        <NewDevelopments></NewDevelopments>
        <AiPowerSearch></AiPowerSearch>
        <LetUsHelpYou></LetUsHelpYou>
        <div className="overlap-8">
          <div className="ellipse-2" />
          <LifeStyle></LifeStyle>
          <img className="ellipse-3" alt="Ellipse" src="/img/ellipse-8.svg" />
          <MovingInMainText></MovingInMainText>
        </div>
        <div className="overlap-17">

          <PropertyList></PropertyList>

        </div>
        <div className="overlap-20">
          <img className="poza" alt="Poza" src="/img/poza-3.png" />
          <Menu></Menu>
          <Logo></Logo>
          <SearchBar></SearchBar>
          <MainSlider></MainSlider>
        </div>
      </div>
    </div>
  );
};
