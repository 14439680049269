export const PropertyListItem = () => {
    return (<div className="group-23">
        <div className="overlap-18">
            <div className="group-24">
                <div className="overlap-group-6">
                    <div className="group-25">
                        <img className="vector-9" alt="Vector" src="/img/vector-3-5.svg" />
                        <img className="vector-10" alt="Vector" src="/img/vector-2-3.svg" />
                    </div>
                    <img className="vector-11" alt="Vector" src="/img/vector-4.svg" />
                    <div className="text-wrapper-45">2Bedrooms</div>
                    <div className="text-wrapper-46">3 Bath</div>
                    <img className="vector-12" alt="Vector" src="/img/vector.svg" />
                    <div className="element-sq-ft"> 1000 sq ft</div>
                </div>
            </div>
            <img className="rectangle-13" alt="Rectangle" src="/img/rectangle-227-1.png" />
            <div className="text-wrapper-47">Marten House</div>
            <div className="text-wrapper-48">Lagos, Nigeria</div>
            <div className="text-wrapper-49">390.000 €</div>
            <div className="frame-17">
                <div className="text-wrapper-50">AR4171582</div>
            </div>
            <div className="CTA-3">
                <div className="text-wrapper-51">View Details</div>
            </div>
        </div>
    </div>)
}