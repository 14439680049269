/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const OtherProjects = ({ property1, className }) => {
  return (
    <div className={`other-projects ${className}`}>
      <div className="our-project">
        <div className="overlap-group">
          <img className="rectangle" alt="Rectangle" src="/img/rectangle-2093.svg" />
          <img className="img" alt="Rectangle" src="/img/rectangle-2094.svg" />
          <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-2095.svg" />
          <div className="div" />
          <div className="group">
            <div className="arrow-left-wrapper">
              <img className="arrow-left" alt="Arrow left" src="/img/arrowleft.svg" />
            </div>
          </div>
        </div>
      </div>
      <div className="frame">
        <div className="ellipse" />
        <div className="rectangle-3" />
        <div className="ellipse" />
        <div className="ellipse" />
        <div className="ellipse" />
        <div className="ellipse" />
      </div>
    </div>
  );
};

OtherProjects.propTypes = {
  property1: PropTypes.oneOf(["default"]),
};
