export const Logo = () => {
    return (
        <div className="group-38">
            <div className="overlap-21">
                <div className="group-39">
                    <div className="group-40">
                        <div className="overlap-group-11">
                            <img className="vector-15" alt="Vector" src="/img/vector-10.svg" />
                            <img className="vector-16" alt="Vector" src="/img/vector-11.svg" />
                            <img className="vector-17" alt="Vector" src="/img/vector-12.svg" />
                            <div className="ellipse-5" />
                            <div className="ellipse-6" />
                        </div>
                    </div>
                    <div className="text-wrapper-71">320</div>
                    <div className="real-estate">REAL ESTATE</div>
                </div>
            </div>
        </div>
    );
}